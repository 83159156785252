.site {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
}

.site-wrap {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}

footer {
  background-color: $footer-bg-color;
  border-top: $footer-border-top;
  color: $footer-color;
  font-size: $footer-font-size;
  font-weight: $footer-font-weight;
  padding: $footer-padding;
  text-align: center;
}
