html {
  font-size: $base-font-size;
}

abbr {
  border-bottom: $abbr-border-bottom;
  cursor: help;
}

p {
  color: #333;
  line-height: 1.5;
  text-align: justify;
}

small,
.small {
  font-size: 0.707rem;
}

sup {
  position: relative;
  vertical-align: middle;
  top: -0.9ex;
}
