
html, body {
  height: auto;
  min-height: 100%;
}

img {
  max-width: 100%;
  border-radius: 3px;
  display: block;
  margin: 0 auto;
}

em img {
  max-width: 100%;
  margin-left: 0;
}

body {
          box-sizing: border-box;
     -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

::selection {
  color: whitesmoke;
  background-color: lightseagreen;
}

/* added by muodov */
img + em {
    display: block;
    text-align: center;
    font-size: 0.7rem;
}
